//
// Copyright 2023 DXOS.org
//

import { DEBUG_PLUGIN } from './meta';

export default [
  {
    'en-US': {
      [DEBUG_PLUGIN]: {
        'plugin name': 'Debug',
        'mutation count': 'Number of mutations',
        'mutation period': 'Mutation period',
        'open devtools label': 'Open DevTools',
        'devtools label': 'DevTools',
        'debug label': 'Debug',
        'settings show debug panel': 'Show Debug panel.',
        'settings show devtools panel': 'Show DevTools panel.',
        'settings wireframe': 'Show wireframes.',
        'settings repair': 'Run repair tool.',
        'settings download diagnostics': 'Download diagnostics.',
        'settings uploaded': 'Uploaded to IPFS',
        'settings uploaded to clipboard': 'URL copied to clipboard.',
        'settings repair success': 'Repair succeeded',
        'settings repair failed': 'Repair failed',
        'settings choose storage adaptor': 'Storage adaptor (worker reload required).',
        'settings storage adaptor idb label': 'IndexedDB',
        'settings storage adaptor opfs label': 'OPFS',
        'settings data store label': 'Data Store',
        'settings storage adapter changed alert':
          'Warning: Swapping the storage adapter will make your data unavailable.',
        'settings space fragmentation': 'Enable AM space fragmentation',
        'open debug panel label': 'Show Debug',
        'client label': 'Client',
        'config label': 'Config',
        'storage label': 'Storage',
        'logs label': 'Logs',
        'diagnostics label': 'Diagnostics',
        'tracing label': 'Tracing',
        'halo label': 'HALO',
        'identity label': 'Identity',
        'devices label': 'Devices',
        'keyring label': 'Keyring',
        'credentials label': 'Credentials',
        'echo label': 'ECHO',
        'spaces label': 'Spaces',
        'space label': 'Space',
        'feeds label': 'Feeds',
        'objects label': 'Objects',
        'automerge label': 'Automerge',
        'queues label': 'Queues',
        'members label': 'Members',
        'metadata label': 'Metadata',
        'mesh label': 'Mesh',
        'signal label': 'Signal',
        'swarm label': 'Swarm',
        'network label': 'Network',
        'agent label': 'Agent',
        'dashboard label': 'Dashboard',
        'search label': 'Search',
        'edge label': 'Edge',
        'workflows label': 'Workflows',
        'traces label': 'Traces',
      },
    },
  },
];
